<template>
  <!-- begin::kb-main -->
  <main class="kb-main" id="kb-live">

    <!-- main-header -->
    <div class="main-header">
      <!-- main-header > header-top -->
      <div class="header-top main-component">
        <div class="header-column">
          <nav class="kb-breadcrumb">
            <ol class="kb-breadcrumb-list">
              <li class="kb-breadcrumb-item"><a href="javascript:" @click="router.push({path: '/main'})" class="kb-breadcrumb-link"><span class="kb-breadcrumb-text">홈</span></a></li>
              <li class="kb-breadcrumb-item is-active" aria-current="page"><span class="kb-breadcrumb-text">LIVE 편성표</span></li>
            </ol>
          </nav>
        </div>
      </div>
      <!-- main-header > header-title -->
      <div class="header-title">
        <h2 class="title">LIVE 편성표</h2>
      </div>
    </div>

    <!-- main-content -->
    <div class="main-content min-component">

      <LiveWeekCalendar
          :is-loading="isLoading"
          :day-func="dayFunc"
          ref="weekCalRef"
      />

      <!-- section:일정 -->
      <section class="content-section">
        <div class="time-menu">
          <ul class="menu-list">
            <li class="menu-item">
              <a href="javascript:" class="menu" @click="dayFunc('')"><span>오늘</span></a>
            </li>
            <li class="menu-item">
              <LiveCalendarDatePicker v-model:selectDate="selectDate"
                                      v-model:isCalendarshow="isCalendarshow"
                                      >
                <template v-slot:slot-btn>
<!--                  style="display: flex; align-items: center; justify-content: center; width: 65px; height: 48px;"-->
                  <a href="javascript:" @click="isCalendarshow = !isCalendarshow" class="menu dropdown-btn kb-btn-datepicker-nav kb-btn-datepicker-prev" :class="{'is-active': isCalendarshow}" style="display: flex; align-items: center; justify-content: center; width: 65px; height: 48px;">
                    <span><img class="icon-cal" src="@/assets/lxp/images/live/ic_cal.svg" alt="" /></span>
                  </a>
                </template>
              </LiveCalendarDatePicker>
            </li>
          </ul>
        </div>
        <div v-if="qrItems.length > 0" class="live-qr">
          <swiper style="width: 600px; text-align: center;"
                  :slides-per-view="1"
                  :centered-slides="false"
                  :loop="false"
                  :draggable="false"
          >
<!--            :navigation="{-->
<!--            prevEl: '.kb-btn-kb-nav-qr-prev',-->
<!--            nextEl: '.kb-btn-kb-nav-qr-next'-->
<!--            }"-->
          <swiper-slide v-for="(item, index) in qrItems" :key="index">
            <div class="qr-image">
              <img :src="getThumbUrl(item.etnPath)" />
            </div>
            <div class="qr-content">
              <p class="qr-text">{{item.distCrseNm}}</p>
<!--              <p class="qr-text">STEP Ⅳ(부동산상담)</p>-->
            </div>
          </swiper-slide>
          </swiper>
        </div>
        <div class="today-learning-nav" style="display: none;">
          <button class="kb-btn-nav kb-btn-kb-nav-prev kb-btn-kb-nav-qr-prev"><i class="icon-arrow-prev"></i></button>
          <button class="kb-btn-nav kb-btn-kb-nav-next kb-btn-kb-nav-qr-next"><i class="icon-arrow-next"></i></button>
        </div>
        <div class="live-table">

          <div v-if="bodies.length == 0" class="contents-empty"><img src="/app/img/img_empty.30f8e704.png" alt="">
            <p class="text">오늘 예정된 학습이 없습니다.</p>
          </div>

          <ul v-else>
            <li v-for="(item, idx) in bodies" :key="idx" class="live-table-item"
                :class="{'is-active' : item.entranceYn === 'ING'}">
              <article>
                <div class="left-item">
                  <div class="time">{{timestampToDateFormat(item.objBgngDt, 'hh:mm')}}</div>
                  <div class="title-img" v-if="item.lrnObjTyCd === '100008'"><img src="@/assets/lxp/images/live/live_title_cloudnow.svg" alt="" /></div>
                  <div class="title-img" v-if="item.lrnObjTyCd === '100026'"><img src="@/assets/lxp/images/live/live_title_learninglive.svg" alt="" /></div>
                  <div class="desc">
                    <h5 class="desc-title">{{item.distCrseNm}}</h5>
                    <p class="desc-meta">
                      <strong class="meta-time">{{timestampToDateFormat(item.objBgngDt, 'hh:mm')}} - {{timestampToDateFormat(item.objEndDt, 'hh:mm')}}</strong>
                      <strong class="meta-text">{{item.objNm}}</strong>
                    </p>
                    <p class="desc-context">{{item.objSumup}}</p>
                  </div>
                </div>
                <div class="right-item">
                  <div v-if="item.ntcCnt > 0" class="live-utils">
                    <button @click="goNtcBoard(item.distCrseSn)"><img src="@/assets/lxp/images/live/ic_alarm.svg" alt="" /></button>
                  </div>
                  <div class="entry">
                    <button class="kb-btn kb-btn-entry" @click="enterNowClass(item)" :class="{'is-active' : item.entranceYn === 'ING'}">입장하기</button>
                  </div>
                </div>
              </article>
            </li>
          </ul>
        </div>
        <div class="warning-text" v-if="bodies.length > 0">
          <p class="text">Internet Explorer를 통해 접속할 경우 진행이 불가능합니다. Internet Explorer로 접속한 연수생께서는 다른 브라우저(ex. 크롬, 엣지 등)로 재접속하여 주시기 바랍니다.
            <br>진행되는 강의 영상을 녹화한 경우 저작권 문제가 발생합니다. 법적조치가 취해질 수 있으니 영상촬영을 금지합니다.
            <br>자격증 과정은 녹화방송을 제공하지 않습니다.</p>
        </div>
      </section>
    </div>
    <!-- //main-content -->

  </main>
  <CloudNowPledgeModal v-if="applyModal"
                       v-model="applyModal"
                       :distCrseSn="pledge.distCrseSn"
                       :lrnerId="pledge.lrnerId"
                       :lrnerNm="pledge.lrnerNm"/>
  <!-- end::kb-main -->
</template>

<script>
import {Swiper, SwiperSlide} from 'swiper/vue/swiper-vue';
import {ref, reactive, computed, onMounted, watch} from "vue";
import {getItems, lengthCheck, timestampToDateFormat} from "@/assets/js/util";
import {useStore} from "vuex";
import {ACT_BOARD_GET_QR, ACT_GET_MY_TRGT_LIST} from "@/store/modules/live/live";
import {getThumbUrl} from "@/assets/js/modules/course/course-common";
import {useRouter} from "vue-router";
import LiveCalendarDatePicker from "@/components/live/LiveCalendarDatePicker";
import LiveWeekCalendar from "@/components/live/LiveWeekCalendar";
import {entranceCloudNowOnCheck} from "@/assets/js/modules/cloud/cloud-common";
import CloudNowPledgeModal from "@/components/cloudNow/CloudNowPledgeModal";

export default {
  name: "LiveCalendarMng",
  components: {LiveWeekCalendar, LiveCalendarDatePicker, Swiper, SwiperSlide, CloudNowPledgeModal},
  setup() {

    const store = useStore();
    const router = useRouter();
    const search = reactive({
      crseMstSn: 0,
      distCrseSn: 0,
      objBgngDt: timestampToDateFormat(new Date().getTime(), 'yyyy-MM-dd') ,
      lrnObjTyCd: '100008',
    });
    const sessionId = computed(() => store.state.auth.session.lrnerId);
    const session = computed(() => store.state.auth.session);
    const isLoading = ref(true);
    const bodies = ref([]);
    const weekCalRef = ref(null)
    const toggles = ref({objBgngDt: false})
    const isCalendarshow = ref(false);
    const selectDate = ref(null);
    const applyModal = ref(false);

    const pledge = reactive({
      distCrseSn: '',
      lrnerId:'',
      lrnerNm:''
    });


    onMounted(() => {
      getCloudNowList();
    })

    const getCloudNowList = () => {
      store.dispatch(`live/${ACT_GET_MY_TRGT_LIST}`, {
        objBgngDt: search.objBgngDt + ' 00:00:00',
        objType: '100008',
        lrnerId: sessionId.value,
        orderByCond: 'Y'
      }).then(res => {
        if (lengthCheck(res)) {
          bodies.value = getItems(res);
          if(session.value.susdyCdDcd !== '2109001' && session.value.susdyCdDcd !== '2109002'){
            bodies.value = bodies.value.filter(x => x.lrnObjTyCd === '100008')
          }
          bodies.value.map((x) => {
            // 시작전, 진행중, 종료로 나뉨
            let entrancetime = 10;
            if (x.crseMstSn == '100331' || x.crseMstSn == '100332') {
              entrancetime = 15;
            }
            if((new Date(x.objBgngDt).getTime() - new Date().getTime())/1000/60 > entrancetime){
              x.entranceYn = 'BEF'
            }else if(((new Date(x.objBgngDt).getTime() - new Date().getTime())/1000/60 < entrancetime) && x.objEndDt > new Date().getTime()) {
              if(x.objEndYn === 'Y'){
                x.entranceYn = 'END';
              }else{
                x.entranceYn = 'ING'
              }
            }else{
              if(x.objEndYn === 'Y'){
                x.entranceYn = 'END';
              }else{
                x.entranceYn = 'ING'
              }
            }
          })
        }else{
          bodies.value = [];
        }
        getLrnBoardQr();
      }).catch(e=>{
        console.error(e);
      })
      isLoading.value = false;
    }

    const qrItems = ref([]);
    const getLrnBoardQr = () => {
      store.dispatch(`live/${ACT_BOARD_GET_QR}`, {
        divCdDcd: 2013005, // qr코드
        lrnerId: sessionId.value,
        expsrBgngDt: search.objBgngDt + ' 00:00:00',
      }).then(res=> {
        if(lengthCheck(res)){
          qrItems.value = getItems(res);
          let cnt = 0;
          qrItems.value.map((x) => {
            // 시작전, 진행중, 종료로 나뉨
            if(x.expsrBgngDt > new Date().getTime()){
              x.qrYn = 'BEF'
            }else if(x.expsrBgngDt < new Date().getTime() && x.expsrEndDt > new Date().getTime()) {
              x.qrYn = 'ING'
              cnt++;
            }else{
              x.qrYn = 'END'
            }
          })
          if(cnt == 0){
            qrItems.value = [];
          }
        }else{
          qrItems.value = [];
        }
      }).catch(e => {
        console.error(e);
      });
    }

    const dayFunc = (year, month, day) => {
      if(year == ''){
        // search.objBgngDt = timestampToDateFormat(new Date().getTime(), 'yyyy-MM-dd')
        let obj = {year: new Date().getFullYear(), month: new Date().getMonth()+1, day: new Date().getDate()}
        weekCalRef.value.selectDate(obj, true);
      }else{
        search.objBgngDt = `${year}-${('0' + month).slice(-2)}-${('0' + day).slice(-2)}`;
      }
      getCloudNowList();
    }

    // 나우 입장
    const enterNowClass = (item) => {
      const params = {
        distCrseSn : item.distCrseSn,
        objDistSn: item.lrnObjDtlDistSn,
        lrnObjDtlDistSn: item.lrnObjDtlDistSn,
        objType: item.lrnObjTyCd,
        lrnerId: session.value.lrnerId,
        etnRefDtl: item.etnRefDtl
      }
      if (item.pledgeYn === "Y" && item.pledgeRsltYn != "Y") {
        pledge.distCrseSn = item.distCrseSn;
        pledge.lrnerId = session.value.lrnerId;
        pledge.lrnerNm = session.value.lrnerNm;
        applyModal.value = true;
      } else {
        entranceCloudNowOnCheck(params);
      }
    }

    const goNtcBoard = (distCrseSn) => {
      router.push({name: 'LiveNotice', params: {distCrseSn: distCrseSn}})
    }

    watch(() => selectDate.value, () => {
      weekCalRef.value.selectDate({year: selectDate.value.year, month: selectDate.value.month, day: selectDate.value.day}, true);
    })

    watch(() => applyModal.value, () => {
      if (applyModal.value === false) {
        getCloudNowList();
      }
    })



    const receiveMessage = (e) => {
      if(e.data.code == 'reloadUrl'){
        location.reload();
      }
    }
    window.addEventListener('message', receiveMessage, false);

    return{
      isLoading,
      bodies,
      qrItems,
      weekCalRef,
      search,
      toggles,
      router,
      isCalendarshow,
      selectDate,
      dayFunc,
      enterNowClass,
      timestampToDateFormat,
      getThumbUrl,
      goNtcBoard,
      applyModal,
      pledge,
      getCloudNowList
    }
  }
}
</script>

<style scoped>

</style>