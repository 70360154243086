<template>
  <div id="calendar-picker" class="dropdown" :class="[isCalendarshow ? 'is-active' : 'is-inactive']" data-id="dropdown-calendar-picker">
    <slot name="slot-btn">
    </slot>
    <div class="dropdown-target">
      <div class="dropdown-box">
        <!-- calendar-picker-container -->
        <div class="calendar-picker-container">
          <!-- calendar-picker-container > calendar-picker -->
          <div class="calendar-picker">
<!--            <div class="picker-title">-->
<!--              <span class="title"></span>-->
<!--              <span class="date"></span>-->
<!--            </div>-->
            <div class="picker-body">
              <div class="datepicker-header">
                <button class="kb-btn-datepicker-nav kb-btn-datepicker-prev" @click="changeMonth(false)"><i class="icon-prev"></i></button>
                <div class="title">{{ currentYearMonth }}</div>
                <button class="kb-btn-datepicker-nav kb-btn-datepicker-next" @click="changeMonth(true)"><i class="icon-next"></i></button>
              </div>
              <div class="datepicker-table datepicker-table-week">
                <table>
                  <thead>
                  <tr>
                    <th>일</th>
                    <th>월</th>
                    <th>화</th>
                    <th>수</th>
                    <th>목</th>
                    <th>금</th>
                    <th>토</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="(week, idx) in currentWeeks" :key="idx">
                    <td v-for="(day, i) in week" :key="`${idx}-${i}`">
                      <template v-if="day.month === currentMonth">
                        <button class="kb-btn-day" :data-id="`data-${day.day}`" @click="handleSelectDate(day)">
                          <span class="text">{{ day.day }}</span>
                        </button>
                      </template>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <!-- //calendar-picker-container -->
      </div>
    </div>
    <div class="dropdown-bg"></div>
  </div>
</template>

<script>
import {computed, reactive, ref, watch} from "vue";
import {
  dateToDateFormat,
  getCalendar,
  getItems,
  lengthCheck,
  ymdStrToDate,
  ymdStrToDateFormat
} from "@/assets/js/util";
import {ACT_GET_MY_TRGT_LIST} from "@/store/modules/live/live";
import {useStore} from "vuex";

export default {
  name: "LiveCalendarDatePicker",
  props: {
    selectDate: Object,
    isCalendarshow: Boolean,
  },
  emits: ['update:isCalendarshow', 'update:selectDate'],
  setup(props, {emit}) {
    const currentDate = new Date();
    // 현재 연월일 데이트 피커 데이터
    const currentYear = ref(currentDate.getFullYear());
    const currentMonth = ref(currentDate.getMonth() + 1);

    // 오늘 연 월 일
    const today = reactive({
      year: currentDate.getFullYear(),
      month: (currentDate.getMonth() + 1),
      day: currentDate.getDate(),
    });

    // 이번주 데이터
    const currentWeeks = computed(() => getCalendar(currentYear.value, currentMonth.value));

    // Datepicker range 일때 두번째 달력 뿌릴 데이터
    // 다음 달이 내년일 경우 연도+1 아니면 이번년도
    const getNextYear = computed(() => {
      if (currentMonth.value === 12) {
        return currentYear.value + 1;
      } else {
        return currentYear.value;
      }
    });

    // 다음달이 내년일 경우 1월 아니면 다음달
    const getNextMonth = computed(() => {
      if (currentMonth.value === 12) {
        return 1;
      } else {
        return currentMonth.value + 1;
      }
    });

    const nextYear = ref(getNextYear.value);
    const nextMonth = ref(getNextMonth.value);

    // 2번째 달력의 주 정보
    const nextWeeks = computed(() => getCalendar(getNextYear.value, getNextMonth.value));

    const todayYmd = `${today.year}${('0' + today.month).slice(-2)}${('0' + today.day).slice(-2)}`;

    const startDt = ref('');
    const endDt = ref('');

    const currentYearMonth = computed(() => {
      // if (currentMonth.value === 12) {
      //   return `${currentYear.value + 1}년 1월`;
      // } else {
        return `${currentYear.value}년 ${currentMonth.value}월`;
      // }
    });

    const nextYearMonth = computed(() => {
      if (currentMonth.value === 12) {
        return `${currentYear.value + 1}년 1월`;
      } else {
        return `${currentYear.value}년 ${currentMonth.value + 1}월`;
      }
    });

    const prevYearMonth = computed(() => {
      if (currentMonth.value === 1) {
        return `${currentYear.value - 1}년 12월`;
      } else {
        return `${currentYear.value}년 ${currentMonth.value - 1}월`;
      }
    });

    // 다음, 이전 연 월 버튼 기능
    const changeMonth = (isNext) => {
      document.getElementsByClassName('kb-btn-day')
      document.querySelectorAll('.kb-btn-day').forEach((x) => {x.classList.remove('is-active')})

      if (isNext) {
        if (currentMonth.value === 12) {
          currentYear.value++;
          currentMonth.value = 1;
        } else {
          currentMonth.value++;
        }

        if (nextMonth.value === 12) {
          nextYear.value++;
          nextMonth.value = 1;
        } else {
          nextMonth.value++;
        }
      } else {
        if (currentMonth.value === 1) {
          currentYear.value--;
          currentMonth.value = 12;
        } else {
          currentMonth.value--;
        }

        if (nextMonth.value === 1) {
          nextYear.value--;
          nextMonth.value = 12;
        } else {
          nextMonth.value--;
        }
      }
      getCloudNowList();
    };

    const init = () => {
      currentYear.value = currentDate.getFullYear();
      currentMonth.value = currentDate.getMonth() + 1;
      nextYear.value = getNextYear.value;
      nextMonth.value = getNextMonth.value;
    };

    const handleSelectDate = (date) => {
      startDt.value = date;
      emit('update:selectDate', startDt.value);
      emit('update:isCalendarshow', false);
    };

    const isPastDay = (date) => {
      if (currentDate < ymdStrToDate(date.ymd)) return false;
      return true;
    };

    watch(() => props.isCalendarshow, () => {
      // 열고 닫을때 오늘 기준 월 보여주기 위해 init()
      if (props.isCalendarshow) {
        init();
      }
    });

    const store = useStore();
    const sessionId = computed(() => store.state.auth.session.lrnerId)
    const nowList = ref([]);
    const getCloudNowList = async() => {
      await store.dispatch(`live/${ACT_GET_MY_TRGT_LIST}`, {
        monthDt: currentYear.value+'-'+currentMonth.value+'-'+'01 00:00:00',
        objType: '100008',
        lrnerId: sessionId.value,
      }).then(res => {
        if (lengthCheck(res)) {
          nowList.value = getItems(res);
          setLiveCheck();
        }else{
          nowList.value = [];
        }
      }).catch(e=>{
        console.error(e);
      })
    }
    getCloudNowList();

    const setLiveCheck = () => {
      currentWeeks.value.filter((week) => {
        week.map((days => {
          nowList.value.map((now) => {
            let year = new Date(now.objBgngDt).getFullYear()
            let month = Number(new Date(now.objBgngDt).getMonth()) < 10 ? '0'+(new Date(now.objBgngDt).getMonth()+1) : (new Date(now.objBgngDt).getMonth()+1)
            let day = Number(new Date(now.objBgngDt).getDate()) < 10 ? '0'+new Date(now.objBgngDt).getDate() : new Date(now.objBgngDt).getDate()
            let ymd = year+''+month+''+day;
            let date = Number(new Date(now.objBgngDt).getDate())
            if(days.ymd.indexOf(ymd) > -1){
              document.querySelector(`[data-id=data-${date}]`).classList.add('is-active')
            }
          })
        }))
      })
    }

    return {
      ymdStrToDate,
      dateToDateFormat,
      ymdStrToDateFormat,

      today,

      currentYear,
      currentMonth,
      // currentDay,
      currentWeeks,

      nextYear,
      nextMonth,
      nextWeeks,

      todayYmd,
      currentYearMonth,
      nextYearMonth,
      prevYearMonth,
      nowList,
      getCloudNowList,

      changeMonth,

      handleSelectDate,


      isPastDay,

      startDt,
      endDt,
    };
  }
}
</script>